import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Menu,
  Popconfirm,
  Popover,
  Tag,
  message
} from 'antd';
import { debounce, filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/filter-selected.svg';
import FilterIcon from '../../../assets/filter.svg';
import api from '../../../common/api';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  formatPhoneNumber,
  formatPhoneNumberWithoutMask
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import { EDIT_TENANT } from '../graphql/Mutations';
import { FETCH_TENANTS, TENANT_FILTER } from '../graphql/Queries';
import LoginModal from './LoginModal';

let scrollDebounce = null;
const initialTenantFilter = {
  skip: 0,
  limit: 10,
  sortOn: 'createdAt',
  sortBy: 'DESC'
};

const initialPaginationValue = {
  total: 0,
  current: 1
};
const TenantTable = () => {
  const {
    getCurrentUser,
    state: { pageSize, filterData, currentUser },
    dispatch
  } = useContext(AppContext);

  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [sorting, setSorting] = useState({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [tenantFilter, setTenantFilter] = useState(initialTenantFilter);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const [updateTenant, { loading: editLoading }] = useMutation(EDIT_TENANT, {
    onError() {}
  });
  const [fetchTenant, { loading, data }] = useLazyQuery(FETCH_TENANTS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setPaginationProp({
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.tenants.count
      });
      setFetchLoading(false);
    },
    onError() {}
  });

  const [tenantFilters] = useLazyQuery(TENANT_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.tenantFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.tenantFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      if (res?.tenantFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    }
  });

  const getFilterData = (confirm) => {
    fetchTenant({
      variables: {
        filter: { ...tenantFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setTenantFilter({
      ...tenantFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        default:
          tenantFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            tenantFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: [filterIndex]
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchTenant({
      variables: {
        filter: {
          ...tenantFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setTenantFilter({
      ...tenantFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      default:
        tenantFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: [dataIndex]
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(filtersCopyState[dataIndex], (item) => item !== value)
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              // className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div className="single-checkbox-div" key={item?.key || item}>
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters && filters[dataIndex]?.length > 0 ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  useEffect(() => {
    fetchTenant({
      variables: {
        where: {
          id: getCurrentUser?.tenantId
        },
        filter: tenantFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = async (pagination, tableFilter, sorter) => {
    setFetchLoading(true);
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSorting(sorter);
    setTenantFilter({ ...tenantFilter, skip });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setTenantFilter({
        ...tenantFilter,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchTenant({
        variables: {
          filter: {
            ...tenantFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setTenantFilter({
        ...tenantFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchTenant({
        variables: {
          filter: {
            ...tenantFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const changeStatus = async (tenantId, tenant) => {
    const tenantInput = {
      name: tenant?.name,
      subDomain: tenant?.domainName,
      description: tenant?.description,
      email: tenant?.mail,
      phoneNo: tenant?.phoneNo,
      isActive: !tenant?.isActive
    };
    const locationInput = {
      addressLine1: tenant?.location?.addressLine1,
      addressLine2: tenant?.location?.addressLine2,
      addressLine3: tenant?.location?.addressLine3,
      city: tenant?.location?.city,
      country: tenant?.location?.country,
      state: tenant?.location?.state,
      zipCode: tenant?.location?.zipCode
    };
    try {
      const changeTenantStatus = await updateTenant({
        variables: {
          where: {
            id: tenantId
          },
          data: {
            tenantInput,
            locationInput
          }
        }
      });
      if (changeTenantStatus) {
        fetchTenant({
          variables: {
            filter: tenantFilter,
            ...(filters && { where: filters })
          }
        });
      }
    } catch {
      setFetchLoading(false);
    }
  };

  const handleEditTenant = (id) => {
    dispatch({ type: 'SET_FILTER_DATA', data: filters });
    history?.push(`${ROUTES?.TENANT}/edit/${id}`);
  };

  const handleViewTenant = (id) => {
    dispatch({ type: 'SET_FILTER_DATA', data: filters });
    history?.push(`${ROUTES?.TENANT}/view/${id}`);
  };

  const handleShowLoginModal = (id) => {
    setRecordId(id);
    setShowLoginModal(true);
  };

  const renderActionButtons = (tenant) => {
    const { isActive, id } = tenant;
    return (
      <div className="d-flex flex-vertical">
        <Button type="text" onClick={() => handleEditTenant(id)}>
          Edit
        </Button>
        <Button type="text" onClick={() => handleViewTenant(id)}>
          View
        </Button>
        <Popconfirm
          title={`Are you sure to ${
            isActive ? 'Mark Inactive' : 'Mark Active'
          }?`}
          onConfirm={() => changeStatus(id, tenant)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="text">
            {isActive ? 'Mark as Inactive' : 'Mark as Active'}
          </Button>
        </Popconfirm>
        <Button type="text" onClick={() => handleShowLoginModal(id)}>
          Login as a Tenant
        </Button>
      </div>
    );
  };

  const onSearchChange = async (value) => {
    setTenantFilter({
      ...tenantFilter,
      skip: value ? 0 : tenantFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchTenant({
      variables: {
        filter: {
          ...tenantFilter,
          skip: value ? 0 : tenantFilter?.limit * (paginationProp?.current - 1),
          search: value
        }
      }
    });
    setFetchLoading(true);
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'firstName',
      ellipsis: true,
      width: 160,
      className: 'max-width-column',
      key: 'name',
      sorter: true,
      sortOrder: sorting?.columnKey === 'name' && sorting?.order,
      render: (userInfo, record) => {
        return `${record?.userInfo?.firstName} ${record?.userInfo?.lastName}`;
      }
    },
    {
      title: 'EMAIL',
      dataIndex: 'userEmail',
      ellipsis: true,
      key: 'userEmail',
      width: 150,
      className: 'max-width-column',
      sorter: true,
      sortOrder: sorting?.columnKey === 'userEmail' && sorting?.order,
      render: (userInfo, record) => (
        <span title={record?.userInfo?.email}>
          {record?.userInfo?.email || '-'}{' '}
        </span>
      )
    },
    {
      title: 'PHONE NO.',
      dataIndex: 'userPhoneNo',
      key: 'userPhoneNo',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sorting?.columnKey === 'userPhoneNo' && sorting?.order,
      width: 150,
      ellipsis: true,
      render: (userInfo, record) => (
        <span
          title={formatPhoneNumber(
            formatPhoneNumberWithoutMask(record?.phoneNo)?.slice(-10)
          )}
        >
          {formatPhoneNumber(
            formatPhoneNumberWithoutMask(record?.phoneNo)?.slice(-10)
          ) || '-'}
        </span>
      )
    },
    {
      title: 'BUSINESS NAME',
      dataIndex: 'name',
      ellipsis: true,
      width: 160,
      className: 'max-width-column',
      key: 'name',
      sorter: true,
      sortOrder: sorting?.columnKey === 'name' && sorting?.order
    },
    {
      title: 'BUSINESS EMAIL',
      dataIndex: 'email',
      ellipsis: true,
      width: 150,
      key: 'email',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sorting?.columnKey === 'email' && sorting?.order,
      render: (email) => <span title={email}>{email || '-'} </span>
    },
    {
      title: 'SUB DOMAIN',
      dataIndex: 'subDomain',
      width: 150,
      ellipsis: true,
      sorter: true,
      sortOrder: sorting?.columnKey === 'subDomain' && sorting?.order,
      className: 'max-width-column',
      key: 'subDomain',
      render: (subDomain) => <span title={subDomain}>{subDomain || '-'} </span>,
      ...filterPopup('subDomain')
    },
    {
      title: 'BUSINESS PHONE NO.',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sorting?.columnKey === 'phoneNo' && sorting?.order,
      width: 180,
      ellipsis: true,
      render: (phoneNo) => (
        <span title={formatPhoneNumber(phoneNo)}>
          {formatPhoneNumber(phoneNo) || '-'}
        </span>
      )
    },
    {
      title: 'STATUS',
      width: 100,
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sorting?.columnKey === 'isActive' && sorting?.order,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <Tag color="green">Active</Tag>;
        }

        return <Tag color="red">Inactive</Tag>;
      }
    },
    {
      align: 'right',
      width: 30,
      fixed: 'right',
      render: (tenant) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={() => renderActionButtons(tenant)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'TENANT',
        userId: currentUser?.id,
        filtersObj: {
          ...filters,
          id: key === 'SELECTED' ? selectedKeys : undefined
        }
      }
    })
      .then((res) => {
        if (res?.data?.message) {
          message?.info(res.data.message);
        }
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setTenantFilter(initialTenantFilter);
    setSorting({});
    fetchTenant({
      variables: { filter: initialTenantFilter }
    });
  };

  const exportContent = (
    <Menu onClick={handleExport}>
      <Menu.Item key="SELECTED" id="selected-export">
        <span>Selected</span>
      </Menu.Item>
      <Menu.Item key="ALL" id="all-export">
        <span>All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="tenants"
        folder="TENANT"
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        id={recordId}
      />
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="Tenant name or other detail.."
            name="Tenant"
            getData={onSearchChange}
          />
        </div>
        <div className="header-buttons">
          <Button
            className="common-button import-button"
            id="user-table-import-btn"
            type="primary"
            onClick={() => handleShowImportModal(true)}
          >
            Import
          </Button>
          <Dropdown
            overlayClassName="export-btn-dropdown"
            overlay={exportContent}
            placement="bottomCenter"
          >
            <Button
              className="common-button export-button"
              id="user-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading || editLoading || fetchLoading}
            columns={columns}
            data={data?.tenants?.data || []}
            onChange={handleTableChange}
            rowKey={(obj) => obj?.id}
            rowSelection={rowSelection}
            paginationConfig={paginationProp}
          />
        )}
      </div>
    </div>
  );
};
export default TenantTable;
