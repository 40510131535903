import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Menu,
  Popconfirm,
  Popover,
  Tag,
  message
} from 'antd';
import { debounce, filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/filter-selected.svg';
import FilterIcon from '../../../assets/filter.svg';
import api from '../../../common/api';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  formatPhoneNumber,
  formatPhoneNumberWithoutMask
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_USER, UPDATE_USER } from '../graphql/Mutations';
import { GET_ADMIN_USERS, USER_FILTER } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1
};

const rolesObj = {
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin'
};

const rolesData = [
  {
    key: 'SUPER_ADMIN',
    value: 'Super Admin'
  },
  {
    key: 'ADMIN',
    value: 'Admin'
  }
];

let scrollDebounce = null;

const UserTable = () => {
  const {
    state: { pageSize, filterData, currentUser },
    dispatch
  } = useContext(AppContext);

  const initialUserFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    showCurrentUser: false
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [sortedInfo, setSortedInfo] = useState({});
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [updateUserMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError() {}
    }
  );

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(
    DELETE_USER,
    {
      onError() {}
    }
  );

  const [fetchUserData, { loading, data }] = useLazyQuery(GET_ADMIN_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.adminUsers?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [userFilters] = useLazyQuery(USER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
      }
      if (res?.userFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    if (pageSize) {
      setUserFilter({ ...userFilter, limit: pageSize });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    if (userFilter?.limit) {
      fetchUserData({
        variables: {
          filter: userFilter,
          ...(filterData && { where: filterData })
        }
      });
    }

    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter?.limit]);

  const getFilterData = (confirm) => {
    fetchUserData({
      variables: {
        filter: { ...userFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setUserFilter({
      ...userFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'roles':
          setFilterLoading(false);
          break;
        default:
          userFilters({
            variables: {
              filter: {
                sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField:
                  filterIndex === 'name'
                    ? ['firstName', 'lastName']
                    : filterIndex,
                showCurrentUser: false
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);

        switch (dataIndex) {
          default:
            userFilters({
              variables: {
                filter: {
                  sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField:
                    filterIndex === 'name'
                      ? ['firstName', 'lastName']
                      : filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleResetRole = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchUserData({
      variables: {
        filter: { ...userFilter, skip: 0, sortOn: 'createdAt', sortBy: 'DESC' },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setUserFilter({
      ...userFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      default:
        userFilters({
          variables: {
            filter: {
              sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField:
                filterIndex === 'name' ? ['firstName', 'lastName'] : filterIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(filtersCopyState[dataIndex], (item) => item !== value)
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex, isContactNo = false, isRole = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'roles' && dataIndex !== 'isActive' && (
            <SearchComponent
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <>
                        {dataIndex === 'roles' ? (
                          <span title={rolesObj[item]}>{rolesObj[item]}</span>
                        ) : (
                          <span
                            title={
                              isContactNo
                                ? formatPhoneNumber(
                                    item?.label || item?.toString()
                                  )
                                : item?.label || item?.toString()
                            }
                          >
                            {isContactNo
                              ? formatPhoneNumber(
                                  item?.label || item?.toString()
                                )
                              : item?.label || item?.toString()}
                          </span>
                        )}
                      </>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          {isRole ? (
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {map(rolesData, (item) => {
                return (
                  <div className="single-checkbox-div" key={item?.key || item}>
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span title={item?.value}>{item?.value}</span>
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => {
                  return (
                    <div
                      className="single-checkbox-div"
                      key={item?.key || item}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={filtersCopyState?.[dataIndex]?.includes(
                          item?.key || item
                        )}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        {dataIndex === 'isActive' ? (
                          <span title={item === true ? 'Active' : 'Inactive'}>
                            {item === true ? 'Active' : 'Inactive'}
                          </span>
                        ) : (
                          <span
                            title={
                              isContactNo
                                ? formatPhoneNumber(
                                    item?.label || item?.toString()
                                  )
                                : item?.label || item?.toString()
                            }
                          >
                            {isContactNo
                              ? formatPhoneNumber(
                                  item?.label || item?.toString()
                                )
                              : item?.label || item?.toString()}
                          </span>
                        )}
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          )}
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleResetRole(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters && filters[dataIndex]?.length > 0 ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleAddEditUser = (editUserData) => {
    if (editUserData?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.USERS}/edit/${editUserData?.id}`);
    } else {
      history?.push(`${ROUTES?.USERS}/add`);
    }
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setUserFilter({ ...userFilter, skip });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleUserStatus = async (editUserData) => {
    const editData = {
      isActive: !editUserData?.isActive,
      email: editUserData?.email,
      roles: editUserData?.roles,
      firstName: editUserData?.firstName,
      lastName: editUserData?.lastName,
      phoneNo: editUserData?.phoneNo
    };
    const response = await updateUserMutate({
      variables: { data: { ...editData }, where: { id: editUserData?.id } }
    });
    if (response?.data?.adminUpdateUser) {
      fetchUserData({
        variables: { filter: userFilter, ...(filters && { where: filters }) }
      });
    }
  };

  const handleDeleteUser = async (editUserData) => {
    const response = await deleteUser({
      variables: { where: { id: editUserData?.id } }
    });
    if (response?.data?.adminDeleteUser) {
      fetchUserData({
        variables: { filter: userFilter, ...(filters && { where: filters }) }
      });
    }
  };

  const renderActionButtons = (editUserData) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="user-table-edit-btn"
          className="b-0"
          onClick={() => {
            handleAddEditUser(editUserData);
          }}
        >
          Edit
        </Button>
        <Button
          id="user-table-edit-btn"
          className="b-0"
          onClick={() => {
            history?.push(`${ROUTES?.USERS}/view/${editUserData?.id}`);
          }}
        >
          View
        </Button>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteUser(editUserData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="user-table-status-btn" className="b-0">
            Remove
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to ${
            editUserData?.isActive ? 'Mark Inactive' : 'Mark Active'
          }?`}
          onConfirm={() => handleUserStatus(editUserData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="user-table-status-btn" className="b-0">
            {editUserData?.isActive ? 'Mark Inactive' : 'Mark Active'}
          </Button>
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (text, record) => {
        return `${record?.firstName} ${record?.lastName}`;
      }
    },
    {
      title: 'EMAIL',
      ellipsis: true,
      dataIndex: 'email',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      key: 'email',
      render: (email) => <span title={email}>{email || '-'} </span>
    },
    {
      title: 'CONTACT',
      width: 180,
      ellipsis: true,
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      render: (phone) => {
        return (
          formatPhoneNumber(formatPhoneNumberWithoutMask(phone)?.slice(-10)) ||
          '-'
        );
      }
    },
    {
      title: 'ROLES',
      width: 250,
      ellipsis: true,
      dataIndex: 'roles',
      key: 'roles',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'roles' && sortedInfo?.order,
      ...filterPopup('roles', false, true),
      render: (roleValues, record) => {
        return roleValues?.map((roleData) => (
          <Tag key={`${roleData}-${record?.id}`}>{rolesObj[roleData]}</Tag>
        ));
      }
    },
    {
      title: 'STATUS',
      width: 120,
      dataIndex: 'isActive',
      key: 'isActive',
      ...filterPopup('isActive'),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = async (value) => {
    setUserFilter({
      ...userFilter,
      skip: value ? 0 : userFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchUserData({
      variables: {
        filter: {
          ...userFilter,
          skip: value ? 0 : userFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'USER',
        userId: currentUser?.id,
        filtersObj: {
          ...filters,
          id: key === 'SELECTED' ? selectedKeys : undefined
        }
      }
    })
      .then((res) => {
        if (res?.data?.message) {
          message?.info(res.data.message);
        }
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setUserFilter(initialUserFilter);
    setSortedInfo({});
    fetchUserData({
      variables: { filter: initialUserFilter }
    });
  };

  const exportContent = (
    <Menu onClick={handleExport}>
      <Menu.Item key="SELECTED" id="selected-export">
        <span>Selected</span>
      </Menu.Item>
      <Menu.Item key="ALL" id="all-export">
        <span>All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="admin-users"
        folder="USER"
      />

      <Portal portalId="header-right-content">
        <Button
          id="user-table-add-btn"
          type="primary"
          onClick={handleAddEditUser}
        >
          Add User
        </Button>
      </Portal>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            // className="list-search-box"
            id="search-container-id"
            placeholder="User name or other detail.."
            name="Users"
            getData={onSearchChange}
          />
        </div>
        <div className="header-buttons">
          <Button
            className="common-button import-button"
            id="user-table-import-btn"
            type="primary"
            onClick={() => handleShowImportModal(true)}
          >
            Import
          </Button>
          <Dropdown
            overlayClassName="export-btn-dropdown"
            overlay={exportContent}
            placement="bottomCenter"
          >
            <Button
              className="common-button export-button"
              id="user-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading || updateUserLoading || deleteUserLoading}
            columns={columns}
            data={data?.adminUsers?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            rowSelection={rowSelection}
          />
        )}
      </div>
    </div>
  );
};

export default UserTable;
