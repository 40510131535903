import Icon, {
  BankOutlined,
  HistoryOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  UserOutlined,
  WalletOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import logoWithText from '../../../assets/logo-with-text.png';
import logo from '../../../assets/logo.png';
import { MODULES, ROUTES, TABLET_BREAKPOINT } from '../../../common/constants';
import lobIconComponent from './iconComponent/lobIconComponent';

const { Sider } = Layout;
const { SubMenu } = Menu;

const routes = [
  {
    route: ROUTES?.USERS,
    moduleKey: MODULES?.USERS_MANAGEMENT
  },
  {
    route: ROUTES?.TENANT,
    moduleKey: MODULES?.TENANT
  },
  {
    route: ROUTES?.LINE_OF_BUSINESSES,
    moduleKey: MODULES?.LINE_OF_BUSINESSES
  },
  {
    route: ROUTES?.IMPORT_EXPORT_QUEUE,
    moduleKey: MODULES?.IMPORT_EXPORT_QUEUE
  },
  {
    route: ROUTES?.PLANS,
    moduleKey: MODULES?.PLANS
  },
  {
    route: ROUTES?.API_LOGS,
    moduleKey: MODULES?.API_LOGS
  }
];
function Sidebar({ location: { pathname }, history }) {
  const [collapsed, setCollapsed] = useState(false);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const {
    state: { windowWidth }
  } = useContext(AppContext);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const LobIcon = (props) => <Icon component={lobIconComponent} {...props} />;

  const onMenuSelect = (e) => {
    history?.push(e.key);
  };

  useEffect(() => {
    if (windowWidth < TABLET_BREAKPOINT) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    map(routes, (item, index) => {
      if (location?.pathname?.includes(item?.route)) {
        setDefaultOpenKeys([item?.moduleKey]);
        setLoaded(true);
      } else if (index === routes?.length - 1) {
        setLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div>
        <div className="gx-layout-sider-header">
          <div>
            <Link to={ROUTES?.MAIN}>
              <img
                src={collapsed ? logo : logoWithText}
                alt="logo"
                className="gx-site-logo"
              />
            </Link>
          </div>
          <div onClick={toggle} className="gx-icon-btn icon pointer">
            {collapsed ? (
              <span className="sidebar-icons">
                <RightCircleOutlined />
              </span>
            ) : (
              <span className="sidebar-icons">
                <LeftCircleOutlined />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="gx-sidebar-content">
        {loaded && (
          <Menu
            theme="lite"
            mode="vertical"
            selectedKeys={[`/${pathname?.split('/')[1]}`]}
            defaultSelectedKeys={[ROUTES?.USERS]}
            onSelect={onMenuSelect}
            defaultOpenKeys={defaultOpenKeys}
          >
            <SubMenu
              key={MODULES?.USERS}
              icon={<UserOutlined />}
              title={MODULES?.USERS_MANAGEMENT}
              className="d-flex align-center"
            >
              <Menu.Item key={ROUTES?.USERS}>{MODULES?.USERS}</Menu.Item>
              {/* <Menu.Item key={ROUTES?.USERS_ROLES}>
              {MODULES?.USERS_ROLES} // commented for now till there is no use of this module.
              </Menu.Item>
              <Menu.Item key={ROUTES?.USERS_PERMISSION}>
              {MODULES?.USERS_PERMISSION}
            </Menu.Item> */}
            </SubMenu>
            <Menu.Item
              key={ROUTES?.TENANT}
              className="d-flex align-center"
              title={
                <>
                  <BankOutlined />
                  &nbsp;
                  <span>{MODULES?.TENANT}</span>
                </>
              }
            >
              <BankOutlined />
              <span>{MODULES?.TENANT}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES?.LINE_OF_BUSINESSES}
              className="d-flex align-center"
              title={
                <>
                  <LobIcon />
                  &nbsp;
                  <span>{MODULES?.LINE_OF_BUSINESSES}</span>
                </>
              }
            >
              <LobIcon />
              <span>{MODULES?.LINE_OF_BUSINESSES}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES?.IMPORT_EXPORT_QUEUE}
              className="d-flex align-center"
              title={
                <>
                  <LobIcon />
                  &nbsp;
                  <span>{MODULES?.IMPORT_EXPORT_QUEUE}</span>
                </>
              }
            >
              <LobIcon />
              <span>{MODULES?.IMPORT_EXPORT_QUEUE}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES?.PLANS}
              className="d-flex align-center"
              title={
                <>
                  <WalletOutlined />
                  &nbsp;
                  <span>{MODULES?.PLANS}</span>
                </>
              }
            >
              <WalletOutlined />
              <span>{MODULES?.PLANS}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES?.API_LOGS}
              className="d-flex align-center"
              title={
                <>
                  <HistoryOutlined />
                  &nbsp;
                  <span>{MODULES?.API_LOGS}</span>
                </>
              }
            >
              <HistoryOutlined />
              <span>{MODULES?.API_LOGS}</span>
            </Menu.Item>
          </Menu>
        )}
      </div>
      <div className="gx-linebar">
        <Menu
          theme="lite"
          mode="vertical"
          selectedKeys={[`/${pathname?.split('/')[1]}`]}
          defaultSelectedKeys={[ROUTES?.JOBS]}
          onSelect={onMenuSelect}
        >
          <SubMenu
            key={MODULES?.PROFILE}
            icon={<UserOutlined />}
            title={MODULES?.PROFILE}
            className="d-flex align-center"
          >
            <Menu.Item key={ROUTES?.PROFILE}>{MODULES?.PROFILE}</Menu.Item>
            <Menu.Item key={ROUTES?.LOGOUT}>{MODULES?.LOGOUT}</Menu.Item>
          </SubMenu>
        </Menu>
        {/* <div className="gx-icon-btn icon pointer" onClick={toggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div> */}
      </div>
    </Sider>
  );
}

export default withRouter(Sidebar);
