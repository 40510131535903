/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  USERS_PERMISSION: '/permission',
  USERS_ROLES: '/roles',
  LOGOUT: '/logout',
  LOGIN: '/login',
  RESET: '/forgot-password',
  CHANGE: '/reset',
  VERIFY: '/verify',
  VERIFY_EMAIL: '/verify-email',
  CHECKLISTS: '/checklists',
  IMPORTS: '/imports',
  NOTIFICATION: '/notification',
  PROFILE: '/profile',
  TENANT: '/tenant',
  BRANDS: '/retailers',
  LINE_OF_BUSINESSES: '/line-of-businesses',
  SUB_AREAS: '/sub-areas',
  PLANS: '/plans',
  API_LOGS: '/api-logs',
  IMPORT_EXPORT_QUEUE: '/import-export-queue'
};

/*  Modules */
export const MODULES = {
  USERS_MANAGEMENT: 'User Management',
  USERS: 'Users',
  USERS_PERMISSION: 'Permissions',
  USERS_ROLES: 'Roles',
  CHECKLISTS: 'Checklists',
  IMPORTS: 'Imports',
  NOTIFICATION: 'Notifications',
  TENANT: 'Tenants',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
  LINE_OF_BUSINESSES: 'Lines of Business',
  PLANS: 'Plans',
  API_LOGS: 'API Logs',
  IMPORT_EXPORT_QUEUE: 'Import/Export Queue'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN'
};

export const READ = 'read';
export const WRITE = 'write';

/* allow file types  */

export const ALLOW_TYPES = ['application/vnd.ms-excel', 'text/csv'];

export const ALLOW_IMPORT_TYPES = ['csv'];

export const ALLOW_FILE_TYPES = [
  'docx',
  'doc',
  'pdf',
  'ppt',
  'pptx',
  'xls',
  'csv',
  'xlsx'
];

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'svg'];
/* Date and time */
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

/* Table Height */
export const TABLE_HEIGHT = 241;
export const TABLE_HEIGHT_SEARCH = 279;

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  SUB_DOMAIN: /^[a-z0-9-]+(?:-[a-z0-9]+)*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const SMALL_TABLET = 1000;
export const TABLET_BREAKPOINT = 1200;
export const BIG_SCREEN = 1600;

export const SKIP_RECORD = 20;

export const FEATURE_MAX_LIMIT = 8;
