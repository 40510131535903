import { gql } from '@apollo/client';

export const GET_IMPORT_EXPORT_QUEUES = gql`
  query importExportQueues(
    $filter: ImportExportQueueFilter!
    $where: ImportExportQueueWhereFilter
  ) {
    importExportQueues(filter: $filter, where: $where) {
      count
      data {
        id
        sourceFile
        entityType
        type
        filters
        jobStarted
        jobCompleted
        status
        downloadLink
      }
    }
  }
`;

export const GET_IMPORT_EXPORT_QUEUES_FILTERS = gql`
  query importExportQueueFilters($filter: ImportExportQueueFilter!) {
    importExportQueueFilters(filter: $filter) {
      count
      data {
        sourceFile
        entityType
        type
        filters
        jobStarted
        jobCompleted
        status
        downloadLink
      }
    }
  }
`;
