import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import ImportExportQueueList from './pages/ImportExportQueueList';

const importExportQueueWrapper = () => {
  return (
    <div className="import-export-queue-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.IMPORT_EXPORT_QUEUE}
          render={(props) => <ImportExportQueueList {...props} />}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default importExportQueueWrapper;
