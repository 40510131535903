import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Empty, Tag, Tooltip } from 'antd';
import { camelCase, debounce, filter, forEach, map, startCase } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/filter-selected.svg';
import FilterIcon from '../../../assets/filter.svg';
import { SKIP_RECORD } from '../../../common/constants';
import { downloadFromURL, formatDate } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import {
  GET_IMPORT_EXPORT_QUEUES,
  GET_IMPORT_EXPORT_QUEUES_FILTERS
} from '../graphql/Queries';

let scrollDebounce = null;

const dateFormatWithoutTime = (dateFormat) => {
  const checkForCapitalFormat = dateFormat?.search(/H/);
  const checkForSmallFormat = dateFormat?.search(/h/);
  if (checkForCapitalFormat > 0) {
    return dateFormat?.substring(0, checkForCapitalFormat);
  }
  if (checkForSmallFormat > 0) {
    return dateFormat?.substring(0, checkForSmallFormat);
  }
  return dateFormat;
};

const ImportExportQueueTable = () => {
  const {
    state: { pageSize, filterData, globalDateFormat },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialImportExportQueueFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'jobStarted',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [importExportQueueFilter, setImportExportQueueFilter] = useState(
    initialImportExportQueueFilter
  );
  const [filters, setFilters] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [importExportQueues, { loading, data }] = useLazyQuery(
    GET_IMPORT_EXPORT_QUEUES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.importExportQueues?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  const [importExportQueueFilters] = useLazyQuery(
    GET_IMPORT_EXPORT_QUEUES_FILTERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (scrollFlag) {
          const optionsCopy = [...filterList];
          forEach(res?.importExportQueueFilters?.data, (item) => {
            optionsCopy?.push(item?.[filterIndex]);
          });
          setFilterList(optionsCopy);
          setScrollFlag(false);
        } else {
          const optionsCopy = [];
          forEach(res?.importExportQueueFilters?.data, (item) => {
            optionsCopy?.push(item?.[filterIndex]);
          });
          setFilterList(optionsCopy);
        }
        setFilterIsEnd(
          res?.importExportQueueFilters?.data?.length < SKIP_RECORD
        );
        setFilterLoading(false);
      },
      onError: () => {
        setFilterLoading(false);
      }
    }
  );

  useEffect(() => {
    importExportQueues({
      variables: {
        filter: importExportQueueFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    importExportQueues({
      variables: {
        filter: { ...importExportQueueFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setImportExportQueueFilter({
      ...importExportQueueFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'jobStarted':
        case 'jobCompleted':
          break;
        default:
          importExportQueueFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'jobStarted':
          case 'jobCompleted':
            break;
          default:
            importExportQueueFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    if (['jobStarted', 'jobCompleted']?.includes(dataIndex)) {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    importExportQueues({
      variables: {
        filter: {
          ...importExportQueueFilter,
          skip: 0,
          sortOn: 'jobStarted',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setImportExportQueueFilter({
      ...importExportQueueFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setImportExportQueueFilter({
        ...importExportQueueFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      importExportQueues({
        variables: {
          filter: {
            ...importExportQueueFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setImportExportQueueFilter({
        ...importExportQueueFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'jobStarted',
        sortBy: 'DESC'
      });
      importExportQueues({
        variables: {
          filter: {
            ...importExportQueueFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'jobStarted',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    setFilterLoading(true);
    switch (dataIndex) {
      case 'jobStarted':
      case 'jobCompleted':
        break;
      default:
        importExportQueueFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const onRangePickerChange = (dateValues, dataIndex) => {
    const rangeObj = {
      from: moment(dateValues?.[0])?.startOf('day'),
      to: moment(dateValues?.[1])?.endOf('day')
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj
    };
    if (!dateValues?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (['jobStarted', 'jobCompleted']?.includes(dataIndex)) {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format={dateFormatWithoutTime(globalDateFormat)}
              disabledDate
              disableFutureDate
              isCurrentDateAllowed
              allowClear={false}
              value={
                filtersCopyState?.[dataIndex]
                  ? [
                      filtersCopyState?.[dataIndex]?.from,
                      filtersCopyState?.[dataIndex]?.to
                    ]
                  : []
              }
              onChange={(rangeValues) =>
                onRangePickerChange(rangeValues, dataIndex)
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'exportStatus' && dataIndex !== 'entityType' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item, index) => {
                  return (
                    <Tag
                      key={`${item?.toString()}_${index}`}
                      closable
                      onClose={() => handleDeleteFilter(item, dataIndex)}
                      className="filter-tag"
                    >
                      <span
                        title={
                          startCase(camelCase(item?.label)) ||
                          startCase(camelCase(item?.toString()))
                        }
                      >
                        {startCase(camelCase(item?.label)) ||
                          startCase(camelCase(item?.toString()))}
                      </span>
                    </Tag>
                  );
                })}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item, index) => {
                  return (
                    <div
                      className="filter-checkbox-section"
                      key={`${item?.toString()}_${index}`}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={filtersCopyState?.[dataIndex]?.includes(
                          item?.key || item
                        )}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="common-checkbox"
                      >
                        <span
                          title={
                            startCase(camelCase(item?.label)) ||
                            startCase(camelCase(item?.toString()))
                          }
                        >
                          {startCase(camelCase(item?.label)) ||
                            startCase(camelCase(item?.toString()))}
                        </span>
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters && filters[dataIndex]?.length > 0 ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const columns = [
    {
      title: 'FILE NAME',
      dataIndex: 'sourceFile',
      key: 'sourceFile',
      sorter: true,
      width: 200,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sourceFile' && sortedInfo?.order,
      className: 'max-width-column',
      render: (sourceFile) => sourceFile || '-'
    },
    {
      title: 'ENTITY',
      dataIndex: 'entityType',
      key: 'entityType',
      ellipsis: true,
      width: 200,
      sorter: true,
      ...filterPopup('entityType'),
      sortOrder: sortedInfo?.columnKey === 'entityType' && sortedInfo?.order,
      className: 'max-width-column',
      render: (entityType) => startCase(camelCase(entityType))
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 200,
      sorter: true,
      ...filterPopup('type'),
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      className: 'max-width-column',
      render: (type) => startCase(camelCase(type))
    },
    {
      title: 'DATE ADDED',
      dataIndex: 'jobStarted',
      key: 'jobStarted',
      sorter: true,
      width: 200,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'jobStarted' && sortedInfo?.order,
      className: 'max-width-column date-column-min-width',
      ...filterPopup('jobStarted'),
      render: (jobStarted) => {
        if (jobStarted) {
          return <span>{formatDate(jobStarted, globalDateFormat)}</span>;
        }
        return '-';
      }
    },
    {
      title: 'DATE FINISHED',
      dataIndex: 'jobCompleted',
      key: 'jobCompleted',
      sorter: true,
      width: 200,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'jobCompleted' && sortedInfo?.order,
      className: 'max-width-column date-column-min-width',
      ...filterPopup('jobCompleted'),
      render: (jobCompleted) => {
        if (jobCompleted) {
          return <span>{formatDate(jobCompleted, globalDateFormat)}</span>;
        }
        return '-';
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 170,
      ellipsis: true,
      sorter: true,
      ...filterPopup('status'),
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (status) => startCase(camelCase(status))
    },
    {
      title: 'ACTION',
      dataIndex: 'downloadLink',
      align: 'right',
      width: 80,
      fixed: 'right',
      render: (downloadLink) => {
        if (downloadLink) {
          return (
            <Tooltip
              placement="top"
              title={<span className="font-500">Download</span>}
            >
              <Button
                type="primary"
                size="large"
                icon={<DownloadOutlined />}
                onClick={() => downloadFromURL(downloadLink)}
              />
            </Tooltip>
          );
        }
        return '-';
      }
    }
  ];

  const onSearchChange = (value) => {
    setImportExportQueueFilter({
      ...importExportQueueFilter,
      skip: value
        ? 0
        : importExportQueueFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    importExportQueues({
      variables: {
        filter: {
          ...importExportQueueFilter,
          skip: value
            ? 0
            : importExportQueueFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  return (
    <div>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="Search all details..."
            name="importExportQueues"
            getData={onSearchChange}
          />
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            className="first-column-table"
            loadingData={loading}
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.importExportQueues?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default ImportExportQueueTable;
